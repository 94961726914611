import React from "react"
import { Container } from "components/library"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Sidebar } from "./sidebar"
import { TranslationKey } from "types/translation"

const items: TranslationKey[] = [
	"terms-of-use-page.sidebar.nav.2.items.Application Of Terms",
	"terms-of-use-page.sidebar.nav.2.items.Changes",
	"terms-of-use-page.sidebar.nav.2.items.Definitions",
	"terms-of-use-page.sidebar.nav.2.items.Your Obligations",
	"terms-of-use-page.sidebar.nav.2.items.Intellectual Property",
	"terms-of-use-page.sidebar.nav.2.items.Disclaimers",
	"terms-of-use-page.sidebar.nav.2.items.Liability",
	"terms-of-use-page.sidebar.nav.2.items.Privacy Policy",
	"terms-of-use-page.sidebar.nav.2.items.Suspension and Termination",
	"terms-of-use-page.sidebar.nav.2.items.General",
]

export const PageContent = () => {
	const { t } = useTranslation()

	return (
		<Container className="flex flex-col gap-10 sm:gap-16 py-8 sm:py-24">
			 <span className="text-4xl tracking-wider sm:text-center sm:text-5xl md:text-7xl">
                {t('privacy-policy-page.heading')}
            </span>

			<section className="items-start grid grid-cols-4 w-full gap-20">
				<Sidebar
					nav1Title="terms-of-use-page.sidebar.nav.1.title"
					nav2Title="terms-of-use-page.sidebar.nav.2.title"
					items={items}
				/>

				<article className="col-span-4 sm:col-span-3 prose-sm sm:prose-lg prose-ul:list-disc prose-h3:scroll-mt-48">
				    <h2 id={t('common.words.terms-of-use')} className="text-5xl scroll-mt-48">
                        {t('common.words.terms-of-use')}
                    </h2>
					<h3 id={t('terms-of-use-page.article.Application Of Terms.heading')}>
						{t('terms-of-use-page.article.Application Of Terms.heading')}
					</h3>
					<ul>
						<li>
							{t('terms-of-use-page.article.Application Of Terms.list.1.content')}
						</li>
						<ul>
							<li>
								{t('terms-of-use-page.article.Application Of Terms.list.1.subitems.1')}
							</li>
							<li>
								{t('terms-of-use-page.article.Application Of Terms.list.1.subitems.2')}
							</li>
						</ul>

						<li>
							{t('terms-of-use-page.article.Application Of Terms.list.2.content')}
						</li>
					</ul>

					<h3 id={t('terms-of-use-page.article.Changes.heading')}>
						{t('terms-of-use-page.article.Changes.heading')}
					</h3>
					<ul>
						<li>
							{t('terms-of-use-page.article.Changes.list.1.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.Changes.list.2.content')}
						</li>
					</ul>
					<strong>
						{t('terms-of-use-page.article.Changes.list.note')}
					</strong>

					<h3 id={t('terms-of-use-page.article.Definitions.heading')}>
						{t('terms-of-use-page.article.Definitions.heading')}
					</h3>
					<p>
						{t('terms-of-use-page.article.Definitions.desc')}
					</p>
					<ul>
						<li>{t('terms-of-use-page.article.Definitions.list.1.content')}</li>
						<li>{t('terms-of-use-page.article.Definitions.list.2.content')}</li>
						<li>{t('terms-of-use-page.article.Definitions.list.3.content')}</li>
						<li>{t('terms-of-use-page.article.Definitions.list.4.content')}</li>
						<li>{t('terms-of-use-page.article.Definitions.list.5.content')}</li>
						<li>{t('terms-of-use-page.article.Definitions.list.6.content')}</li>
						<li>{t('terms-of-use-page.article.Definitions.list.7.content')}</li>
						<li>{t('terms-of-use-page.article.Definitions.list.8.content')}</li>
					</ul>

					<h3 id={t('terms-of-use-page.article.Your Obligations.heading')}>
						{t('terms-of-use-page.article.Your Obligations.heading')}
					</h3>
					<ul>
						<li>
							{t('terms-of-use-page.article.Your Obligations.list.1.content')}
						</li>

						<li>
							{t('terms-of-use-page.article.Your Obligations.list.2.content')}
						</li>
						<ul>
							<li>
								{t('terms-of-use-page.article.Your Obligations.list.2.subitems.1')}
							</li>
							<li>
								{t('terms-of-use-page.article.Your Obligations.list.2.subitems.2')}
							</li>
						</ul>

						<li>
							{t('terms-of-use-page.article.Your Obligations.list.3.content')}
						</li>
						<ul>
							<li>
								{t('terms-of-use-page.article.Your Obligations.list.3.subitems.1')}
							</li>
							<li>
								{t('terms-of-use-page.article.Your Obligations.list.3.subitems.2')}
							</li>
						</ul>

						<li>
							{t('terms-of-use-page.article.Your Obligations.list.4.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.Your Obligations.list.5.content')}
						</li>
					</ul>

					<h3 id={t('terms-of-use-page.article.Intellectual Property.heading')}>
						{t('terms-of-use-page.article.Intellectual Property.heading')}
					</h3>
					<p>
						{t('terms-of-use-page.article.Intellectual Property.desc')}
					</p>

					<h3 id={t('terms-of-use-page.article.Disclaimers.heading')}>
						{t('terms-of-use-page.article.Disclaimers.heading')}
					</h3>
					<ul>
						<li>
							{t('terms-of-use-page.article.Disclaimers.list.1.content')}
						</li>
						<ul>
							<li>
								{t('terms-of-use-page.article.Disclaimers.list.1.subitems.1')}
							</li>
							<li>
								{t('terms-of-use-page.article.Disclaimers.list.1.subitems.2')}
							</li>
							<li>
								{t('terms-of-use-page.article.Disclaimers.list.1.subitems.3')}
							</li>
							<li>
								{t('terms-of-use-page.article.Disclaimers.list.1.subitems.4')}
							</li>
						</ul>

						<li>
							{t('terms-of-use-page.article.Disclaimers.list.2.content')}
						</li>
					</ul>

					<h3 id={t('terms-of-use-page.article.Liability.heading')}>
						{t('terms-of-use-page.article.Liability.heading')}
					</h3>
					<ul>
						<li>
							{t('terms-of-use-page.article.Liability.list.1.content')}
						</li>
						<ul>
							<li>
								{t('terms-of-use-page.article.Liability.list.1.subitems.1')}
							</li>
							<li>
								{t('terms-of-use-page.article.Liability.list.1.subitems.2')}
							</li>
						</ul>

						<li>
							{t('terms-of-use-page.article.Liability.list.2.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.Liability.list.3.content')}
						</li>
					</ul>

					<h3 id={t('terms-of-use-page.article.Privacy Policy.heading')}>
						{t('terms-of-use-page.article.Privacy Policy.heading')}
					</h3>
					<ul>
						<li>
							{t('terms-of-use-page.article.Privacy Policy.list.1.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.Privacy Policy.list.2.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.Privacy Policy.list.3.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.Privacy Policy.list.4.content')}
						</li>

						<li>
							{t('terms-of-use-page.article.Privacy Policy.list.5.content')}
						</li>
						<ul>
							<li>
								{t('terms-of-use-page.article.Privacy Policy.list.5.subitems.1')}
							</li>
							<li>
								{t('terms-of-use-page.article.Privacy Policy.list.5.subitems.2')}
							</li>
						</ul>

						<li>
							{t('terms-of-use-page.article.Privacy Policy.list.6.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.Privacy Policy.list.7.content')}
						</li>
					</ul>

					<h3 id={t('terms-of-use-page.article.Suspension and Termination.heading')}>
						{t('terms-of-use-page.article.Suspension and Termination.heading')}
					</h3>
					<ul>
						<li>
							{t('terms-of-use-page.article.Suspension and Termination.list.1.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.Suspension and Termination.list.2.content')}
						</li>
					</ul>

					<h3 id={t('terms-of-use-page.article.General.heading')}>
						{t('terms-of-use-page.article.General.heading')}
					</h3>
					<ul>
						<li>
							{t('terms-of-use-page.article.General.list.1.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.General.list.2.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.General.list.3.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.General.list.4.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.General.list.5.content')}
						</li>
						<li>
							{t('terms-of-use-page.article.General.list.6.content')}
						</li>
					</ul>
				</article>
			</section>
		</Container>
	)
}
